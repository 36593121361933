<template>
  <div>
    <banner
      title="Kebijakan"
      description="Daftar undang-undang, pemendikbud, dan keputusan menteri terkait Perbukuan."
      :breadcrumbs="breadcrumbs"
      :icon="require('@/assets/home/decoration/kebijakan.svg')"
    />

    <section id="content" class="mt-4">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="sidebar">
              <ul>
                <li
                  v-for="filterOption in filterOptions"
                  :key="filterOption.value"
                  class="d-flex align-items-center mb-2 text-grey-500 p-2 rounded-lg"
                  style="cursor:pointer"
                  @click="filter = filterOption.value"
                  :class="{
                    ' bg-grey-400/10': filterOption.value === filter,
                  }"
                >
                  <svg-icon :name="`kebijakan-${filterOption.value.toLowerCase()}`"/>
                  <span class="pl-2">
                    {{ filterOption.label }}
                  </span>
                </li>
                <li role="button" @click.prevent="filter = 'Perka'" class="mb-2 p-1" :class="{'bg-grey-400/10': filter === 'Perka'}">
                  <a href="#">
                    <i class="fa-regular fa-fw fa-lg fa-file-lines text-success"></i> &nbsp;&nbsp;Peraturan Kepala Badan
                  </a>
                </li>
                <li role="button" @click.prevent="filter = 'News'" class="mb-2 p-1" :class="{'bg-grey-400/10': filter === 'News'}">
                  <a href="#">
                    <i class="fas fa-fw fa-lg fa-newspaper"></i> &nbsp;&nbsp;Berita
                  </a>
                </li>
                <li role="button" @click.prevent="filter = 'lainnya'" class="mb-2 p-1" :class="{'bg-grey-400/10': filter === 'lainnya'}">
                  <a href="#">
                    <i class="fas fa-fw fa-lg fa-ellipsis-vertical"></i> &nbsp;&nbsp;Lainnya
                  </a>
                </li>
                <li role="button" @click.prevent="filter = 'Berlaku'" class="mb-2 p-1" :class="{'bg-grey-400/10': filter === 'Berlaku'}">
                  <a href="#">
                    <i class="fas fa-fw fa-lg fa-file-circle-check text-success"></i> &nbsp;&nbsp;Berlaku
                  </a>
                </li>
                <li role="button" @click.prevent="filter = 'Tidak Berlaku'" class="mb-2 p-1" :class="{'bg-grey-400/10': filter === 'Tidak Berlaku'}">
                  <a href="#">
                    <i class="fas fa-fw fa-lg fa-file-circle-xmark text-secondary"></i> &nbsp;&nbsp;Tidak Berlaku
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-9">
            <div v-if="$store.state.loadPage" class="loading text-center">
              <img src="@/assets/loading-2.gif" alt="" class="img-fluid" />
            </div>
            <template v-else>
              <div class="input-group mb-3" style="width: 300px;">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-transparent pr-2">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </span>
                </div>
                <input
                  v-model="keyword"
                  class="form-control pl-0 border-left-0"
                  placeholder="Cari kata kunci"
                />
              </div>
              <div class="table-responsive border">
                <table class="table table-condensed ">
                  <template
                    v-if="
                      filteredPolicies[0].category.name == 'News' &&
                        filteredPolicies != ''
                    "
                  >
                    <tr class="thead-light">
                      <th>Informasi Berita</th>
                    </tr>
                    <tr
                      v-for="(policy, index) of splittedPolicies"
                      :key="index"
                    >
                      <td>
                        <a :href="policy.featured_image" target="_blank">{{
                          policy.title
                        }}</a>
                      </td>
                    </tr>
                  </template>
                  <template v-else-if="filteredPolicies != ''">
                    <tr class="thead-light">
                      <!-- <th></th> -->
                      <th>No</th>
                      <th>Jenis Regulasi</th>
                      <th>Nomor Regulasi</th>
                      <th>Judul Regulasi</th>
                      <th>Berlaku/Tidak Berlaku</th>
                      <th>Opsi</th>
                    </tr>
                    <tr
                      v-for="(policy, index) of splittedPolicies"
                      :key="index"
                    >
                      <!-- <td>
                        <svg-icon
                          :name="`kebijakan-${policy.category.slug}`"
                          :key="filter + keyword"
                        />
                      </td> -->
                      <td>{{ index + 1 }}</td>
                      <td>{{ policy.category.name }}</td>
                      <td>{{ policy.sk_number }}</td>
                      <td>{{ policy.title }}</td>
                      <td>{{ policy.isvalid == '1' ? 'Berlaku' : 'Tidak Berlaku' }}</td>
                      <td width="75" class="text-center">
                        <div class="dropdown">
                          <i
                            class="fas fa-fw fa-ellipsis"
                            data-toggle="dropdown"
                            style="cursor:pointer"
                          />
                          <div
                            class="dropdown-menu"
                            style="border-radius:.5rem"
                          >
                            <a
                              @click="show(policy.featured_image)"
                              class="mr-2 btn-xs btn-read dropdown-item d-flex align-items-center"
                              data-url="https://static.cdn-cdpl.com/sibi/1097_AccMF_rekrutmen_calonpenilai_2021.pdf"
                              data-type="PDF"
                              href="#"
                            >
                              <div
                                class="px-1 rounded-lg"
                                style="background:#E8D6FF;"
                              >
                                <svg-icon
                                  name="eye"
                                  style="width:1.2rem"
                                ></svg-icon>
                              </div>
                              <span class="pl-2 text-grey-500">
                                Lihat
                              </span>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a
                              type="button"
                              :href="policy.featured_image"
                              target="_blank"
                              class="btn-xs mr-2 btn-push-statistic dropdown-item d-flex align-items-center"
                              data-id="8"
                              data-activity="download"
                            >
                              <div class="px-1 rounded-lg bg-primary-100/50">
                                <svg-icon
                                  name="download"
                                  style="width:1.2rem"
                                ></svg-icon>
                              </div>
                              <span class="pl-2 text-grey-500"> Unduh</span>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <tfoot>
                    <tr>
                      <td colspan="99">
                        <div
                          class="d-flex justify-content-between align-items-center"
                          v-if="filteredPolicies.length"
                        >
                          <span>
                            Menampilkan {{ perPage }} dari
                            {{ filteredPolicies.length }} hasil
                          </span>
                          <pagination
                            class="m-0"
                            :current-page="pageNumber"
                            :total-data="filteredPolicies.length"
                            :total-page="totalPage"
                            :key="totalPage"
                            :max-page="3"
                            @change="onChangePage"
                          />
                        </div>
                        <div v-else class="text-center">
                          Data tidak ditemukan
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </template>

            <div class="row">
              <div class="col-lg-12">
                <nav class="courses-pagination mt-50">
                  <div class="row my-4 text-right">
                    <div class="col-md-12">
                      <nav id="pagination">
                        <!-- <strong>1</strong
                        ><a
                          href="http://sibi.local/kebijakan/2"
                          data-ci-pagination-page="2"
                          >2</a
                        ><a
                          href="http://sibi.local/kebijakan/2"
                          data-ci-pagination-page="2"
                          rel="next"
                          >&gt;</a
                        > -->
                      </nav>
                      <!-- courses pagination -->
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="modal" id="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Kebijakan</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <embed
              id="embed"
              type="application/pdf"
              width="100%"
              height="400px"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.sidebar {
  border: 1px solid #dbddf0;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
<script>
import $ from "jquery";
import { mapActions, mapState } from "vuex";
import SvgIcon from "../../components/common/SvgIcon.vue";
import Banner from "../../components/homepage/Banner.vue";
import Pagination from "../../components/common/Pagination.vue";
export default {
  components: { SvgIcon, Banner, Pagination },
  watch: {
    filter() {
      this.pageNumber = 1;
    },
  },
  data() {
    return {
      breadcrumbs: [
        {
          label: "Beranda",
          url: "/",
        },
        {
          label: "Kebijakan",
          url: "",
        },
      ],
      keyword: "",
      type: {
        undangUndang: "",
        permen: "",
      },
      filter: "semua",
      filterOptions: [
        {
          label: "Semua",
          value: "semua",
        },
        {
          label: "Undang-undang",
          value: "Undang-undang",
        },
        {
          label: "Peraturan Pemerintah",
          value: "PP",
        },
        {
          label: "Peraturan Menteri",
          value: "Permen",
        },
        {
          label: "Keputusan Menteri",
          value: "Kepmen",
        },
        {
          label: "Surat Keputusan",
          value: "SK",
        },
      ],
      perPage: 10,
      pageNumber: 1,
    };
  },
  computed: {
    ...mapState(["policies"]),
    filteredPolicies() {
      let data = this.policies;

      if (this.filter === "Berlaku") {
        data = data.filter((policy) => policy.isvalid == '1');
      } else if (this.filter === "Tidak Berlaku") {
        data = data.filter(policy => policy.isvalid == '0');
      } else if (this.filter !== "semua") {
        data = this.filteredType;
      } else if (this.filter === "semua") {
        data = this.policies.filter(
          (policy) => policy.category.name !== "News"
        );
      }

      return data.filter((policy) => {
        return (
          policy.title.toLowerCase().includes(this.keyword.toLowerCase()) ||
          (policy.sk_number && policy.sk_number.toLowerCase().includes(this.keyword.toLowerCase())) ||
          policy.category.name.toLowerCase().includes(this.keyword.toLowerCase())
        );
      });
    },
    splittedPolicies() {
      return this.filteredPolicies.slice(
        (this.pageNumber - 1) * this.perPage,
        this.pageNumber * this.perPage
      );
    },
    totalPage() {
      return Math.ceil(this.filteredPolicies.length / this.perPage);
    },
    filteredType() {
      return this.policies.filter((el) => el.category.name === this.filter);
    },
  },
  methods: {
    ...mapActions(["fetchAllPolicy"]),
    show(data) {
      // let url = $(this).attr("data-url");

      $("#embed").attr("src", data);
      $("#modal").modal("show");

      return false;
    },
    onChangePage(page) {
      this.pageNumber = page;
    },
  },
  created() {
    this.fetchAllPolicy();
  },
};
</script>
